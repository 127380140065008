import { purgeProperties } from '@area17/a17-helpers';
import setAriaAttribute from '../utils/aria';

var toggleTOC = function(bt) {
  const list = document.querySelector('[role="tablist"]');
  const klass = 'navbarMenu__pages--toc';

  function _handleClicks(event) {
    event.preventDefault();

    bt.blur();

    if (!list.classList.contains(klass)) {
      list.classList.add(klass);
      setAriaAttribute(bt, true);
    } else {
      list.classList.remove(klass);
      setAriaAttribute(bt, false);
    }
  }

  this.destroy = function() {
    // remove specific event handlers
    bt.removeEventListener('click', _handleClicks, false);

    // remove properties of this behavior
    purgeProperties(this);
  };

  this.init = function() {
    bt.addEventListener('click', _handleClicks, false);
  };
};

export default toggleTOC;
