import { triggerCustomEvent } from '@area17/a17-helpers';
import * as Barba from '../../libs/barba';

const klassLoading = 'pjax--loading';
const klassHeaderActive = 'header--active';
const klassHeaderHover = 'headerPost__nav--hover';
const mainSelector = '[data-pjax-wrapper]';

export const scrollTop = Barba.BaseTransition.extend({
  start: function() {
    Promise
      .all([this.newContainerLoading, this.scrollToTopOfPage()])
      .then(this.finish.bind(this));
  },

  scrollToTopOfPage: function() {
    var deferred = Barba.Utils.deferred();

    if (window.A17.searched) {
      triggerCustomEvent(document, 'search:hide');
    }

    triggerCustomEvent(document, 'panel:hide');

    var main  = document.querySelector(mainSelector);
    if (main) {
      main.classList.add(klassLoading);
    }

    // Reset active UI
    var hoveredMenu  = document.querySelector('.' + klassHeaderHover);
    if (hoveredMenu) {
      hoveredMenu.classList.remove(klassHeaderHover);
    }

    var activeMenu = document.querySelector('.' + klassHeaderActive);
    if (activeMenu) {
      activeMenu.classList.remove(klassHeaderActive);
    }

    deferred.resolve();

    return deferred.promise;
  },

  finish: function() {
    var self = this;
    var timing = 350;

    setTimeout(function () {
      // scroll to top (we won't scroll if we are using prev/arrow of the browser)
      if (A17.latestClickElement) {
        window.scrollTo(0,0);
      }

      var main  = document.querySelector(mainSelector);
      if (main) {
        main.classList.remove(klassLoading);
      }

      self.done();
    }, timing);
  }
});
