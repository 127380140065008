/* Highly inspired by http://lazyload.dev.area17.com/lazyload.js - Thank you Mike, keep up the good work! :) */
import { purgeProperties } from '@area17/a17-helpers';

var lazyload = function(container) {

    // set up
    const maxFrameCount = 10; // 60fps / 10 = 6 times a second
    let frameCount;
    let els = [];
    let elsLength;

    /**
     * Converts HTML collections to an array
     * @private
     * @param {Array} array to convert
     * a loop will work in more browsers than the slice method
     */
    function htmlCollectionToArray(collection) {
      let a = [];
      let i = collection.length;
      for (a = [], i = collection.length; i;) {
        a[--i] = collection[i];
      }
      return a;
    }

    /**
     * Remove dupes from array
     * @private
     * @param {Array} array to clean up
     * http://stackoverflow.com/questions/1584370/how-to-merge-two-arrays-in-javascript-and-de-duplicate-items/28631880#answer-1584377
     */
    function arrayConcatUnique(arr1,arr2) {
      let a = arr1.concat(arr2);
      for(let i = 0; i < a.length; ++i) {
        for(let j = i+1; j < a.length; ++j) {
          if(a[i] === a[j]) {
            a.splice(j--, 1);
          }
        }
      }
      return a;
    }

    /**
     * Checks if an element is in the viewport
     * @private
     * @param {Node} element to check.
     * @returns {Boolean} true/false.
     */
    function elInViewport(el) {
      el = (el.tagName === 'SOURCE') ? el.parentNode : el;
      const rect = el.getBoundingClientRect();
      return ((rect.top >= 0 || rect.bottom >= 0) && rect.left >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight));
    }

    /**
     * On loaded, removes event listener, removes data- attributes
     * @private
     */
    function loaded() {
      let self = this;
      callbackImgLoaded(self);
    }

    function callbackImgLoaded(el) {
      el.removeEventListener('load', loaded, false);
      el.removeEventListener('error', loaded, false);
      removeDataAttrs(el);
      callbackLoaded(el);

      // Picturefill the new image
      if (typeof picturefill === 'function') {
        picturefill({ elements: [el] });
      }
    }

    /**
     * Add a CSS class to the parent (for styling purpose)
     */
    function callbackLoaded(el) {
        setTimeout(function() {
          el.parentElement.classList.add('js--lazyloaded');
       }, 250);
    }

    function loadedVideo() {
        let self = this;

        // play by default on ipad
        if(self.paused) {
          self.play();
        }

        callbackLoaded(self);

        self.removeEventListener('canplay', loadedVideo, false);
    }

    /**
     * Removes data- attributes
     * @private
     * @param {Node} element to update
     */
    function removeDataAttrs(el) {
      el.removeAttribute('data-src');
      el.removeAttribute('data-srcset');
    }

    /**
     * Update an element
     * @private
     * @param {Node} element to update
     * @param {html5} good browser or bad browser?
     */
    function updateEl(el) {
      const srcset = el.getAttribute('data-srcset');
      const src = el.getAttribute('data-src');

      //
      if (srcset) {
        // if source set, update
        el.srcset = srcset;
      }
      if (src) {
        // if source set, update
        el.src = src;
      }
    }

    function getIsImageComplete(el) {
        return el.complete && el.naturalWidth !== undefined;
    }

    /**
     * Loops images, checks if in viewport, updates src/src-set
     * @private
     */
    function setSrcs() {
      let i;
      // browser check
      if(typeof document.querySelectorAll === undefined || !('addEventListener' in window) || !window.requestAnimationFrame || typeof document.body.getBoundingClientRect === undefined) {
        // bad browsers
        for (i = 0; i < elsLength; i++) {
          updateEl(els[i]);
          removeDataAttrs(els[i]);
        }
      } else {
        // good browsers
        // debounce checking
        if (frameCount === maxFrameCount) {
          // update cache of this for the loop
          elsLength = els.length;
          for (i = 0; i < elsLength; i++) {
            // check if this array item exists, hasn't been loaded already and is in the viewport
            if (els[i] && els[i].lazyloaded === undefined && elInViewport(els[i])) {
              // cache this array item
              const thisEl = els[i];
              // set this array item to be undefined to be cleaned up later
              els[i] = undefined;
              // give this element a property to stop us running twice on one thing
              thisEl.lazyloaded = true;

              // add an event listener to remove data- attributes on load
              if(thisEl.tagName === 'IMG') {
                thisEl.addEventListener('load', loaded, false);
                thisEl.addEventListener( 'error', loaded, false);
              }

              // update attributes
              updateEl(thisEl);

              // for IMGs
              if(thisEl.tagName === 'IMG') {
                // check if image is already complete (cached ?)
                const isComplete = getIsImageComplete(thisEl);
                if(isComplete) {
                  callbackImgLoaded(thisEl);
                }
              }

              // for SOURCE
              if(thisEl.tagName === 'SOURCE') {
                removeDataAttrs(thisEl);
              }

              // for SOURCE inside html5 VIDEO
              if(thisEl.parentElement.tagName === 'VIDEO') {
                const videoEl = thisEl.parentElement;

                // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
                if (videoEl.readyState > 2) {
                    // play by default on ipad
                    if(videoEl.paused) {
                      videoEl.play();
                    }
                    callbackLoaded(videoEl);
                } else {
                    // load and play the video
                    videoEl.addEventListener('canplay', loadedVideo, false);
                    videoEl.load();
                }
              }
            }
          }
          // clean up array
          for (i = 0; i < elsLength; i++) {
            if (els[i] === undefined) {
              els.splice(i, 1);
            }
          }
          // reset var to decide if to continue running
          elsLength = els.length;
          // will shortly be set to 0 to start counting
          frameCount = -1;
        }

        // run again? kill if not
        if (elsLength > 0) {
          frameCount++;
          window.requestAnimationFrame(setSrcs);
        }
      }
    }

    /**
     * Inspect element
     * @public
     * @param {Node} element in which to look
     */
    function lazyload(contextAttr) {
      let context = contextAttr || document;
      const newEls = context.querySelectorAll('img[data-src], img[data-srcset], source[data-srcset], iframe[data-src], video > source[data-src]');
      els = arrayConcatUnique(els,htmlCollectionToArray(newEls));

      elsLength = els.length;
      frameCount = maxFrameCount;
      setSrcs();
    }

    this.destroy = function() {
      // remove properties of this behavior
      purgeProperties(this);
    };

    this.init = function() {
      // go go go go go
      lazyload(container);
    };
};

export default lazyload;
