import { purgeProperties, triggerCustomEvent } from '@area17/a17-helpers';

/* Search with autocomplete */
var dropdown = function(container) {
  const trigger = container.querySelector('[data-dropdown-trigger]');
  const klassActive = 'dropdown--active';

  function _isOpen() {
    return container.classList.contains(klassActive);
  }

  function _isDropdownLink(el) {
    return el.classList.contains('dropdown__link');
  }

  function _show() {
    container.classList.add(klassActive);
  }

  function _hide() {
    container.classList.remove(klassActive);
  }

  function _hideFromOutside(event) {
    const bt = event.target;
    if (!_isDropdownLink(bt) && _isOpen()) _hide();
  }

  function _handleClick(event) {
    event.preventDefault();
    event.stopPropagation();

    if (_isOpen()) {
      _hide();
    } else {
      _show();
      setTimeout(function () {
        document.addEventListener('click', _hideFromOutside, false);
      },10);
    }
  }

  this.destroy = function() {
    trigger.removeEventListener('click', _handleClick, false);
    if(_isOpen()) {
      document.removeEventListener('click', _hideFromOutside, false);
    }
      // remove properties of this behavior
    purgeProperties(this);
  };

  this.init = function() {
    // regular submit event

    trigger.addEventListener('click', _handleClick, false);
  };
};

export default dropdown;
