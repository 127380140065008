import * as Barba from '../libs/barba';

/* Google Analytics tracker */
export default function() {
  if(typeof ga === 'undefined') return false;

  // On page change
  Barba.Dispatcher.on("newPageReady", function(currentStatus, oldStatus, container, currentHTML) {
    if(Barba.HistoryManager.prevStatus()) {
      ga('set', 'page', location.pathname);
      ga('send', 'pageview');
    }
  });

  // On search completed
  document.addEventListener('analytics:search', function() {
    ga('send', 'event', 'Search', 'submit', 'Search term');

    // track a new page view here too
    ga('set', 'page', location.pathname);
    ga('send', 'pageview');
  });
}
