import { purgeProperties } from '@area17/a17-helpers';

var headerDropdown = function(container) {
  const bts = container.querySelectorAll('a');
  const klassHover = 'headerPost__nav--hover';

  function _openDropDown() {
    container.classList.add(klassHover);
  }

  function _overDropdown() {
    _openDropDown();
  }

  function _handleClicks(event) {
    // on non touch device there is no hover action so you need click to open
    if (!container.classList.contains(klassHover)) {
        _openDropDown();
        return false;
    }
  }

  function _closeDropDown() {
    // temporary deactivate hover actions
    if (!A17.touch) {
        container.removeEventListener('mouseover', _overDropdown);
        setTimeout(function() {
            container.addEventListener('mouseover', _overDropdown);
        }, 200);
    }

    container.classList.remove(klassHover);
  }

  function _leaveDropdown() {
    _closeDropDown();
  }

  function _init() {
    if (bts.length === 0) {
      return false;
    }

    [].map.call(bts, function(bt) {
      bt.addEventListener('click', _handleClicks, false);
    });

    // Dropdown when no touch devices
    if (!A17.touch) {
      container.addEventListener('mouseover',  _overDropdown);
      container.addEventListener('mouseleave', _leaveDropdown);
    }
  }

  this.destroy = function() {
    if (bts.length > 0) {
      // remove specific event handlers
      [].map.call(bts, function(bt){
        bt.removeEventListener('click', _handleClicks, false);
      });
    }

    // Dropdown when no touch devices
    if (!A17.touch) {
      container.removeEventListener('mouseover',  _overDropdown);
      container.removeEventListener('mouseleave', _leaveDropdown);
    }

    // remove properties of this behavior
    purgeProperties(this);
  };

  this.init = function() {
    _init();
  };
};

export default headerDropdown;
