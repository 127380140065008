import { purgeProperties, triggerCustomEvent, scrollToY } from '@area17/a17-helpers';
import { supportsPushState, getURLWithoutQuery, replaceState } from '../utils/pushState';
import setAriaAttribute from '../utils/aria';
import * as Barba from '../libs/barba';
import htmlClasses from '../utils/htmlClasses';
import keyCodes from '../utils/keyCodes';

var toggleSearch = function(bt) {
  const htmlElement = document.documentElement;
  const input = document.querySelector('form input[type="search"]');
  const searchPermalink = input.getAttribute('data-permalink');
  const searchFallback = input.getAttribute('data-fallback-url');
  const searchResults  = document.querySelector('[data-search-results]');
  const content = document.querySelector('[data-content]');
  const overlay = document.querySelector('[data-header-overlay]');
  const currentUrl = getURLWithoutQuery();
  const isSearchPermalink = htmlElement.classList.contains(htmlClasses.search);

  let isOpen = false;

  //use buttonToggle to track whether action is from closing search or normal links
  let buttonToggleState;

  function _scrollTop() {
    scrollToY({ offset: 0, easing: 'easeOut' });
  }

  function _show() {
    if(isOpen) { return false; }

    isOpen = true;

    htmlElement.classList.add(htmlClasses.searching);
    input.focus();

    _resetUI();

    window.addEventListener('keyup', _keyPressed, false);
    overlay.addEventListener('click', _hideFromOverlay, false);
    setAriaAttribute(bt, true);
  }

  function _resetUI() {
    // hide the navbar, the search results and the autocomplete
    triggerCustomEvent(document, 'navbar:hide');
    triggerCustomEvent(document, 'navbarSearchResults:hide');
    triggerCustomEvent(document, 'autocomplete:hide');
  }

  function _hide() {
    if(!isOpen) { return false; }

    isOpen = false;

    // remove html classes that is dealing with search
    htmlElement.classList.remove(htmlClasses.searching, htmlClasses.search, htmlClasses.autocomplete);

    window.removeEventListener('keyup', _keyPressed, false);
    overlay.removeEventListener('click', _hideFromOverlay, false);
    setAriaAttribute(bt, false);

    // empty search results
    searchResults.innerHTML = '';

    // reset the page content
    if (window.A17.searched) {
      // hide the navbar
      _resetUI();

      window.A17.searched = false;

      if(content) {
        if (!isSearchPermalink && window.A17.beforeSearchContent) {
          content.innerHTML = window.A17.beforeSearchContent;
          triggerCustomEvent(document,'page:updated', { el: content });

          window.A17.beforeSearchContent = null;

          // revert url to previous page only if action is from search close button
          if (buttonToggleState == 'closed') {replaceState(currentUrl);}

          // Animate scroll to top position
          _scrollTop();
        } else {
          // redirect to the fallback page : the homepage only if action is from search close button
          if (buttonToggleState == 'closed') {Barba.Pjax.goTo(searchFallback);}
        }
      } else {
        // Animate scroll to top position
        _scrollTop();
      }
    }

    // reset value of the input
    input.value = '';
  }

  function _hideFromOverlay() {
    if(isOpen) {

      if (window.A17.searched) {
        triggerCustomEvent(document, 'autocomplete:hide');
      } else {
        _hide();
      }

      return false;
    }
  }

  function _handleClicks(event) {
    event.preventDefault();
    event.currentTarget.blur(); // blur button

    if (!isOpen) {
      buttonToggleState = 'open';
      _show();
    }else {
      buttonToggleState = 'closed';
      _hide();
    }
  }

  function _keyPressed(event) {
    const isEscKey = Boolean(event.which === keyCodes.escape || event.keyCode === keyCodes.escape); // pressing escape
    const isFocusedInput = Boolean(document.activeElement === input);
    if (isEscKey && isFocusedInput) { _hide(); } // hide when pressing esc and when the field is focused
  }

  function _initSearchPermalink() {
    if (htmlElement.classList.contains(htmlClasses.search)) {
      _show();
      window.A17.searched = true;
    }
  }

  this.destroy = function() {
    // remove specific event handlers
    bt.removeEventListener('click', _handleClicks, false);
    if (isOpen) {
      window.removeEventListener('keyup', _keyPressed, false);
      content.removeEventListener('click', _hideFromOverlay, false);
    }

    document.removeEventListener('page:updated', _initSearchPermalink, false);

    // remove properties of this behavior
    purgeProperties(this);
  };

  this.init = function() {

    bt.addEventListener('click', _handleClicks, false);
    document.addEventListener('search:hide', _hide, false);

    document.addEventListener('page:updated', _initSearchPermalink, false);
    if (isSearchPermalink) {
      _initSearchPermalink();
    }
  };
};

export default toggleSearch;
