/* States classes that can be added on the html root element */
export default {
	backspace: 8,
	comma: 188,
	delete: 46,
	down: 40,
	end: 35,
	enter: 13,
	escape: 27,
	home: 36,
	left: 37,
	pageDown: 34,
	pageUp: 33,
	period: 190,
	right: 39,
	space: 32,
	tab: 9,
	up: 38
};
