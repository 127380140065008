import { purgeProperties } from '@area17/a17-helpers';
import hljs from 'highlight.js/lib/highlight';
import markdown from 'highlight.js/lib/languages/markdown';
import xml from 'highlight.js/lib/languages/xml';
import php from 'highlight.js/lib/languages/php';
import sql from 'highlight.js/lib/languages/sql';
import bash from 'highlight.js/lib/languages/bash';
import css from 'highlight.js/lib/languages/css';
import scss from 'highlight.js/lib/languages/scss';
import javascript from 'highlight.js/lib/languages/javascript';

hljs.registerLanguage('css', css);
hljs.registerLanguage('scss', scss);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('markdown', markdown);
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('php', php);
hljs.registerLanguage('sql', sql);

/* Search with autocomplete */
var highlight = function(container) {
  var codeBlocks = Array.from(container.querySelectorAll('.ql-syntax')) || [];

  this.destroy = function() {
    purgeProperties(this);
  };

  this.init = function() {
    // regular submit event
    window.requestAnimationFrame(() => {
      codeBlocks.forEach(codeBlock => hljs.highlightBlock(codeBlock));
    });
  };
};

export default highlight;
