import { purgeProperties } from '@area17/a17-helpers';
import setAriaAttribute from '../utils/aria';

var toggleSearchResults = function(bt) {
  const htmlElement = document.documentElement;
  const navbar = document.querySelector('.js--navbarsearchResults');
  const klass = 'navbar--expanded';
  const klassHTML = 'page-resultsExpanded';

  let isOpen = false;

  function _handleResize(event) {
    if(A17.currentMediaQuery === 'large' || A17.currentMediaQuery === 'xlarge') { _hide(event); }
  }

  function _hide(event) {
    if(!isOpen) { return false; }

    navbar.classList.remove(klass);
    htmlElement.classList.remove(klassHTML);
    setAriaAttribute(bt, false);

    _unbindHideEvents();

    isOpen = false;
    window.A17.navbar = false;
  }

  function _show(event) {
    if(isOpen) { return false; }

    navbar.classList.add(klass);
    htmlElement.classList.add(klassHTML);
    setAriaAttribute(bt, true);

    _bindHideEvents();

    isOpen = true;
    window.A17.navbar = true;
  }

  function _bindHideEvents() {
    document.addEventListener('resized', _handleResize);
  }

  function _unbindHideEvents() {
    document.removeEventListener('resized', _handleResize);
  }

  function _handleClicks(event) {
    event.preventDefault();

    const bt = event.currentTarget;
    bt.blur();

    if (!navbar.classList.contains(klass)) {
      _show(event);
    } else {
      _hide(event);
    }
  }

  this.destroy = function() {
    // remove specific event handlers
    bt.removeEventListener('click', _handleClicks, false);
    document.removeEventListener('nanavbarSearchResultsbar:hide', _hide, false);
    if (isOpen) { _unbindHideEvents(); }
    // remove properties of this behavior
    purgeProperties(this);
  };

  this.init = function() {
    bt.addEventListener('click', _handleClicks, false);
    document.addEventListener('navbarSearchResults:hide', _hide, false);
  };
};

export default toggleSearchResults;
