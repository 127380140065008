import { purgeProperties, ajaxRequest, triggerCustomEvent } from '@area17/a17-helpers';
import { supportsPushState, getURLWithoutQuery, replaceState } from '../utils/pushState';
import Mark from 'mark.js';
import forEachNodelist from '../utils/forEachNodelist.js';
import htmlClasses from '../utils/htmlClasses';
import keyCodes from '../utils/keyCodes';

/* Search with autocomplete */
var search = function(input) {
  const htmlElement = document.documentElement;
  const searchPermalink = input.getAttribute('data-permalink');
  const searchEndpoint = input.getAttribute('data-url');
  const autocompleteEndpoint = input.getAttribute('data-autocomplete');
  const searchResults = document.querySelector('[data-search-results]');
  const form = document.querySelector('[data-search-form]');
  const autocomplete = document.querySelector('[data-search-autocomplete]');
  const content = document.querySelector('[data-content]');
  const isSearchPermalink = htmlElement.classList.contains(htmlClasses.search);

  const minChar = 2;
  const debounceDelay = 350;
  let timer = null;
  let submitBlocked = false;
  let isLoading = false;
  let query = '';
  let autocompleteQuery = '';
  const klassActive = 'theme-link';

  // Is search opened ?
  function _isSearchOpen() {
    return htmlElement.classList.contains(htmlClasses.searching);
  }

  // Is autocomplete open ?
  function _isAutocompleteOpen() {
    return htmlElement.classList.contains(htmlClasses.autocomplete);
  }

  function _callbackSearch() {
    // highlight search results
    const markInstance = new Mark(searchResults);
    markInstance.unmark({
      done: function(){
        markInstance.mark(decodeURIComponent(query), { separateWordSearch: false });
      }
    });

    window.A17.searched = true;
  }

  function _submitAutocomplete(event) {
    event.preventDefault();
    input.value = event.target.innerHTML;

    submitBlocked = false;
    _submit(event);
  }

  function _hideSuggestions() {
    clearTimeout(timer);
    _showSuggestions([]);
  }

  function _showSuggestions(data) {
    let bts = autocomplete.querySelectorAll('a');

    // unbind events
    forEachNodelist(bts, function (bt) {
      bt.removeEventListener('click', _submitAutocomplete);
      htmlElement.classList.remove(htmlClasses.autocomplete);
    });

    // Create the list markup based on results
    let list = '';
    if (data.length) {
      list += '<ul>';
      for (var i = 0; i < data.length; i++) {
        list += '<li><a href="#" class="theme-link--over">' + data[i].label + '</a></li>';
      }
      list += '</ul>';
    }
    autocomplete.innerHTML = list;

    if (data.length) {
      bts = autocomplete.querySelectorAll('a');

      // bind events
      forEachNodelist(bts, function (bt) {
        bt.addEventListener('click', _submitAutocomplete, false);
      });

      htmlElement.classList.add(htmlClasses.autocomplete);
    }
  }

  function _autocomplete(event) {
    const newQuery = encodeURIComponent(event.target.value);

    if (autocompleteQuery === newQuery) { return false; } // no changes in the autocomplete query

    triggerCustomEvent(document, 'autocomplete:hide');

    if (!htmlElement.classList.contains(htmlClasses.searching)) { return false; } // search form is not opened

    if (newQuery.length <= minChar) { return false; } // query is too short

    if (query === newQuery && window.A17.searched) { return false; } // no changes in the query
    if (event.which === keyCodes.escape || event.keyCode === keyCodes.escape) { return false; } // esc key pressed
    if (!autocompleteEndpoint) { return false; } // no autocomplete

    autocompleteQuery = newQuery;

    ajaxRequest({
      url: autocompleteEndpoint,
      type: 'GET',
      data: {
        query : autocompleteQuery
      },
      requestHeaders: [
        {
          header: 'Content-Type',
          value: 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      ],
      onSuccess: function(data) {
        if (!_isSearchOpen()) {
          _hideSuggestions();
        } else {
          if(!isLoading) {
            _showSuggestions(JSON.parse(data));
          }
        }
      },
      onError: function(data) {
        console.warn(data);
        _hideSuggestions();
      }
    });
  }

  /* Autocomplete + keyboard navigation */
  function _keyDown(event) {
    switch ( event.keyCode ) {
      case keyCodes.pageUp:
        _selectAutocomplete('previousPage', event);
        return false;
        break;
      case keyCodes.pageDown:
        _selectAutocomplete('nextPage', event);
        return false;
        break;
      case keyCodes.up:
        _selectAutocomplete('previous', event);
        return false;
        break;
      case keyCodes.down:
        _selectAutocomplete('next', event);
        return false;
        break;
      case keyCodes.tab:
        _selectAutocomplete('next', event);
        return false;
        break;
      case keyCodes.enter:
        _enterAutocomplete(event);
        return false;
        break;
    }

    // lets autocomplete
    if(timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(function() {
      if(!isLoading) {
        _autocomplete(event);
      }
    }, debounceDelay);
  }

  function _selectAutocomplete(direction, event) {
    if(_isAutocompleteOpen()) {
      // Prevents moving cursor to beginning/end or tabbing to next element
      event.preventDefault();

      let bts = autocomplete.querySelectorAll('a');
      let index = -1;
      forEachNodelist(bts, function (bt, i) {
        if (bt.classList.contains(klassActive)) index = i;
      });

      if (/^previous/.test(direction)) {
        index = index <= 0 ? 0 : index - 1;
      }

      if (/^next/.test(direction)) {
        index = index >= bts.length - 1 ? bts.length - 1 : index + 1;
      }

      forEachNodelist(bts, function (bt, i) {
        bt.classList.remove(klassActive);
        if (i === index) bt.classList.add(klassActive);
      });
    }
  }

  function _enterAutocomplete(event) {
    if(_isAutocompleteOpen()) {
      const bt = autocomplete.querySelector('.' + klassActive);
      if(bt) {
        event.preventDefault();
        bt.click();
      }
    }
  }

  function _submit(event) {
    // unfocus the submit button
    form.querySelector('[type="submit"]').blur();

    const newQuery = encodeURIComponent(input.value);

    if (!_isSearchOpen()) { return false; } // search form is not opened

    if (submitBlocked) { return false; } // submit is blocked
    if (newQuery === '') { return false; } // no query at all
    if (query === newQuery && window.A17.searched) { return false; } // no changes in the query
    if (event.which === keyCodes.escape || event.keyCode === keyCodes.escape) { return false; } // esc key pressed

    // hide the navbar if necessary
    triggerCustomEvent(document, 'navbar:hide');
    triggerCustomEvent(document, 'navbarSearchResults:hide');
    // hide autocomplete
    triggerCustomEvent(document, 'autocomplete:hide');

    isLoading = true;

    ajaxRequest({
      url: searchEndpoint + '/' + newQuery,
      type: 'GET',
      requestHeaders: [
        {
          header: 'Content-Type',
          value: 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      ],
      onSuccess: function(data) {
        if (!_isSearchOpen()) {
          return false; // search form is not opened
        }

        // unfocus the query field (to hide the keyboard on mobile)
        const isFocusedInput = Boolean(document.activeElement === input);
        if (isFocusedInput) { input.blur(); }

        // hide autocomplete
        _hideSuggestions();

        // save current query
        query = newQuery;

        if(searchResults) {
          searchResults.innerHTML = data;

          if (content) {
            if (!window.A17.searched) { // Search is not active already
              if (content.innerHTML) {
                window.A17.beforeSearchContent = content.innerHTML;
              }
            }

            const url = searchPermalink + '/' + query;
            replaceState(url);

            // this is hiding the semi-opaque overlay
            htmlElement.classList.add(htmlClasses.search);
            content.innerHTML = '';
          }

          _callbackSearch();

          triggerCustomEvent(document,'page:updated', { el: searchResults });

          // track new page view
          triggerCustomEvent(document, 'analytics:search');

          isLoading = false;
        }
      },
      onError: function(data) {
        console.warn(data);

        isLoading = false;
      }
    });
  }

  /* submit event on the form */
  function _submitForm(event) {
    event.preventDefault();
    _submit(event);
  }

  function _focusField(event) {
    // hide the navbar on smaller breakpoints
    if (A17.currentMediaQuery === 'xsmall') {
      triggerCustomEvent(document, 'navbar:hide');
    }
  }

  this.destroy = function() {
    // remove specific event handlers
    if (autocompleteEndpoint) {
      input.removeEventListener('keydown', _keyDown, false);
      // custom event to hide the autocomplete list
      document.removeEventListener('autocomplete:hide', _hideSuggestions, false);
    }

    input.removeEventListener('focus', _focusField, false);

    form.removeEventListener('submit', _submitForm, false);

    // remove properties of this behavior
    purgeProperties(this);
  };

  this.init = function() {
    // input.addEventListener('keyup', debounce(function(event) { _submit(event); }, 500), false);
    if (autocompleteEndpoint) {
      input.addEventListener('keydown', _keyDown, false);
      // custom event to hide the autocomplete list
      document.addEventListener('autocomplete:hide', _hideSuggestions, false);
    }

    input.addEventListener('focus', _focusField, false);

    // regular submit event
    form.addEventListener('submit', _submitForm, false);

    // Search permalink pages
    if (isSearchPermalink) {
      query = encodeURIComponent(input.value);
      _callbackSearch();
    }
  };
};

export default search;
