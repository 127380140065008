export function updateHeader (namespace) {
  const links = document.querySelectorAll('.header__nav a');
  const klass = 's--active';

  [].map.call(links, function(link) {
    if (link.getAttribute('data-namespace') === namespace) {
      link.classList.add(klass);
    } else {
      link.classList.remove(klass);
    }
  });
}
