import { purgeProperties, triggerCustomEvent } from '@area17/a17-helpers';
import { getURLWithoutQuery, replaceState } from '../utils/pushState';
import keyCodes from '../utils/keyCodes';
import forEachNodelist from '../utils/forEachNodelist.js';

var togglePanel = function(bt) {
  const htmlElement = document.documentElement;
  const panel = document.querySelector('[data-panel]');
  const closeBtn = document.querySelector('[data-panel-close]');
  const currentCards = panel.querySelectorAll('[href="' + location.href + '"]');
  const klassActive = 'page-panel';
  const currentUrl = getURLWithoutQuery();
  let isOpen = false;

  function _show() {
    if (isOpen) { return false; }

    isOpen = true;
    htmlElement.classList.add(klassActive);
    window.addEventListener('keyup', _keyPressed, false);
    closeBtn.addEventListener('click', _hideFromPanel, false);
  }

  function _hide() {
    if (!isOpen) { return false; }

    isOpen = false;
    htmlElement.classList.remove(klassActive);
    window.removeEventListener('keyup', _keyPressed, false);
    panel.removeEventListener('click', _hideFromPanel, false);
  }

  function _hideFromCard(event) {
    event.preventDefault();
    _hide();
  }

  function _hideFromPanel(event) {
    closeBtn.blur();
    _hide();
  }

  function _handleClicks(event) {
    event.preventDefault();
    event.currentTarget.blur(); // blur button

    if (!isOpen) {
      _show();
    } else {
      _hide();
    }
  }

  function _keyPressed(event) {
    const isEscKey = Boolean(event.which === keyCodes.escape || event.keyCode === keyCodes.escape); // pressing escape
    if (isEscKey) {
      _hide();
    }
  }

  this.destroy = function() {

    // remove specific event handlers
    bt.removeEventListener('click', _handleClicks, false);
    document.removeEventListener('panel:hide', _hide, false);

    // unbind events
    forEachNodelist(currentCards, function (bt) {
      bt.removeEventListener('click', _hideFromCard, false);
    });

    if (isOpen) {
      window.removeEventListener('keyup', _keyPressed, false);
      panel.removeEventListener('click', _hideFromPanel, false);
    }

    // remove properties of this behavior
    purgeProperties(this);
  };

  this.init = function() {
    // bind events
    forEachNodelist(currentCards, function (bt) {
      bt.addEventListener('click', _hideFromCard, false);
    });

    bt.addEventListener('click', _handleClicks, false);
    document.addEventListener('panel:hide', _hide, false);
  };
};

export default togglePanel;
