const inBrowser = typeof window !== 'undefined';

export const supportsPushState = inBrowser && (function () {
  const ua = window.navigator.userAgent;

  if (
    (ua.indexOf('Android 2.') !== -1 || ua.indexOf('Android 4.0') !== -1) &&
    ua.indexOf('Mobile Safari') !== -1 &&
    ua.indexOf('Chrome') === -1 &&
    ua.indexOf('Windows Phone') === -1
  ) {
    return false;
  }

  return window.history && 'pushState' in window.history;
})();

// use User Timing api (if present) for more accurate key precision
const Time = inBrowser && window.performance && window.performance.now ? window.performance : Date;

function genKey() {
  return Time.now().toFixed(3);
}

let _key = genKey();

export function getStateKey () {
  return _key;
}

export function setStateKey (key) {
  _key = key;
}

export function pushState (url, replace) {
  // try...catch the pushState call to get around Safari
  // DOM Exception 18 where it limits to 100 pushState calls
  const history = window.history;
  try {
    if (replace) {
      history.replaceState({ key: _key }, '', url);
    } else {
      _key = genKey();
      history.pushState({ key: _key }, '', url);
    }
  } catch (e) {
    window.location[replace ? 'replace' : 'assign'](url);
  }
}

export function replaceState (url) {
  if ( window.location === window.parent.location ) { // do not replace state when loaded in iframes
    pushState(url, true);
  }
}

export function getURLWithoutQuery () {
  return location.protocol + '//' + location.host + location.pathname;
}

export function getURLWithoutAnchor () {
  return location.href.replace(/#.*$/, '');
}
