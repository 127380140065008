import { purgeProperties } from '@area17/a17-helpers';
import setAriaAttribute from '../utils/aria';

var toggleNavbarMenu = function(bt) {
  const navbar = bt.parentElement;
  const list = bt.nextElementSibling;
  const klass = 'navbarMenu__guides--active';
  const overlay = navbar.nextElementSibling;

  function _close(event) {
    list.style.maxHeight = '';
    navbar.classList.remove(klass);
    setAriaAttribute(bt, false);
  }

  function _open(event) {
    const fullHeight = list.scrollHeight;
    // list.style.transitionDuration = fullHeight/1000 + "s";
    list.style.maxHeight = fullHeight + 'px';
    navbar.classList.add(klass);
    setAriaAttribute(bt, true);
  }

  function _handleClicks(event) {
    event.preventDefault();

    const bt = event.currentTarget;
    bt.blur();

    if (!navbar.classList.contains(klass)) {
      _open(event);
    } else {
      _close(event);
    }
  }

  this.destroy = function() {
    // remove specific event handlers
    bt.removeEventListener('click', _handleClicks, false);
    overlay.removeEventListener('click', _close, false);

    // remove properties of this behavior
    purgeProperties(this);
  };

  this.init = function() {
    bt.addEventListener('click', _handleClicks, false);
    overlay.addEventListener('click', _close, false);
  };
};

export default toggleNavbarMenu;
