import { purgeProperties, triggerCustomEvent } from '@area17/a17-helpers';
import ClipboardJS from 'clipboard';

/* Search with autocomplete */
var blockCopy = function(container) {
  var copy = {
    btn: container.querySelector('[data-blockCopy-btn]'),
    target: container.querySelector('[data-blockCopy-target]'),
    successMessage: container.dataset.blockcopySuccesMessage,
    clipboardJS: null,
    timeout: null,
    attributes: {
     successReveal: 'data-blockCopy-success-reveal'
    }
  };

  var variations = {
    selectItems: Array.from(container.querySelectorAll('[data-blockCopy-variation-select]')) || [],
    bodyItems: Array.from(container.querySelectorAll('[data-blockCopy-variation-body]')) || [],
    attributes: {
      selectedItem: 'data-blockCopy-variation-selected',
      selectedBody: 'data-blockCopy-variation-body-selected'
    }
  };

  function _updateCopyTarget (content) {
    if (copy.target) {
      copy.target.innerHTML = content;
    }
  }

  function _switchVariation (variationId) {
    var variation = variations.bodyItems.find(item => item.dataset.blockcopyVariationBody === variationId);
    var variationSelect = variations.selectItems.find(item => item.dataset.blockcopyVariationSelect === variationId);
    if (!variation) {
      return;
    }

    variations.selectItems.forEach(item => {
      item.removeAttribute(variations.attributes.selectedItem);
    });

    variations.bodyItems.forEach(item => {
      item.removeAttribute(variations.attributes.selectedBody);
    });

    variation.setAttribute(variations.attributes.selectedBody, '');
    variationSelect.setAttribute(variations.attributes.selectedItem, '');
    _updateCopyTarget(variation.innerHTML);
  }

  function _handleVariationSelectClick (event) {
    var target = event.currentTarget;
    if (!target) {
      return;
    }
    var id = target.dataset.blockcopyVariationSelect;
    if (!id) {
      return;
    }
    _switchVariation(id);
  }

  function _registerEvents () {
    variations.selectItems.forEach(item => {
      item.addEventListener('click', _handleVariationSelectClick);
    });
  }

  function _disposeEvents () {
    variations.selectItems.forEach(item => {
      item.removeEventListener('click', _handleVariationSelectClick);
    });
  }

  function _handleCopySuccess (event) {
    if (copy.timeout) {
      return;
    }
    var successEl = document.createElement('p');
    successEl.classList.add('block-copy__alert');
    successEl.innerText = copy.successMessage;
    document.body.appendChild(successEl);
    window.requestAnimationFrame(function () {
      successEl.addEventListener('transitionend', function endReveal () {
        successEl.removeEventListener('transitionend', endReveal);
        successEl.addEventListener('transitionend', function hide () {
          successEl.removeEventListener('transitionend', hide);
          window.requestAnimationFrame(function () {
            successEl.remove();
          });
        });
        copy.timeout = window.setTimeout(function () {
          successEl.removeAttribute(copy.attributes.successReveal);
          window.clearTimeout(copy.timeout);
          copy.timeout = null;
        }, 1500);
      });
      successEl.setAttribute(copy.attributes.successReveal, '');
    });
  }

  function _initCopyClipboardJs () {
    if (copy.btn && copy.target) {
      copy.clipboardJS = new ClipboardJS(copy.btn, {
        target: function () {
          return copy.target;
        }
      });

      copy.clipboardJS.on('success', _handleCopySuccess);
    }
  }

  this.destroy = function() {
      // remove properties of this behavior
    _disposeEvents();
    if (copy.clipboardJS) {
      copy.clipboardJS.destroy();
    }
    purgeProperties(this);
  };

  this.init = function() {
    _initCopyClipboardJs();
    _registerEvents();
  };
};

export default blockCopy;
