import { purgeProperties, triggerCustomEvent } from '@area17/a17-helpers';
import setAriaAttribute from '../utils/aria';
import keyCodes from '../utils/keyCodes';

var toggleNavbar = function(bt) {
  const htmlElement = document.documentElement;
  let navbar = null;
  let overlay = null;
  let wrapper = null;
  const klass = 'navbar--active';
  const klassHTML = 'page-header';

  let isOpen = false;
  let lastScrollPos = 0;

  function _handleResize(event) {
    if (/large$/.test(A17.currentMediaQuery)) {
      _hide();
    }
  }

  function _refreshDom() {
    // update wrapper, navbar and overlay dom (because this elements are changing when searching)
    wrapper = document.querySelector('main');
    navbar = document.querySelector('[role="navigation"]');
    overlay = document.querySelectorAll('[data-navbar-overlay]');
  }

  function _show(event) {
    if(isOpen) { return false; }

    _refreshDom();

    isOpen = true;
    window.A17.navbar = true;

    _bindHideEvents();

    lastScrollPos = window.pageYOffset;

    htmlElement.classList.add(klassHTML);
    if(wrapper) { wrapper.style.top = '-' + lastScrollPos + 'px'; }
    if(navbar) { navbar.classList.add(klass); }

    setAriaAttribute(bt, isOpen);

    // close the search if not active
    if (!window.A17.searched) { triggerCustomEvent(document, 'search:hide'); }
  }

  function _hide(event) {
    if(!isOpen) { return false; }

    _refreshDom();
    _unbindHideEvents();

    htmlElement.classList.remove(klassHTML);
    if(wrapper) { wrapper.style.top = ''; }
    if(navbar) { navbar.classList.remove(klass); }

    if(lastScrollPos) {
      window.scrollTo(0, lastScrollPos);
    }

    isOpen = false;
    window.A17.navbar = false;

    setAriaAttribute(bt, isOpen);
  }

  function _keyPressed(event) {
    if (event.which === keyCodes.escape || event.keyCode === keyCodes.escape) { // 27 : esc
      _hide();
    }
  }

  function _bindHideEvents() {
    document.addEventListener('mediaQueryUpdated', _handleResize);
    window.addEventListener('keyup', _keyPressed, false);
    if (overlay && overlay.length) {
      for (let i = 0, j = overlay.length; i < j; i++) {
        overlay[i].addEventListener('click', _hide, false);
      }
    }
  }

  function _unbindHideEvents() {
    document.removeEventListener('mediaQueryUpdated', _handleResize);
    window.removeEventListener('keyup', _keyPressed, false);
    if (overlay && overlay.length) {
      for (let i = 0, j = overlay.length; i < j; i++) {
        overlay[i].removeEventListener('click', _hide, false);
      }
    }
  }

  function _handleClicks(event) {
    if (document.activeElement) { document.activeElement.blur(); }

    if (isOpen) {
      _hide();
    } else {
      _show();
    }
  }

  this.destroy = function() {
    _refreshDom();

    // remove specific event handlers
    bt.removeEventListener('click', _handleClicks, false);

    document.removeEventListener('navbar:hide', _hide, false);
    if (isOpen) { _unbindHideEvents(); }
    // remove properties of this behavior
    purgeProperties(this);
  };

  this.init = function() {
    _refreshDom();

    bt.addEventListener('click', _handleClicks, false);
    document.addEventListener('navbar:hide', _hide, false);
  };
};

export default toggleNavbar;
