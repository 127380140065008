import { purgeProperties } from '@area17/a17-helpers';
import keyCodes from '../utils/keyCodes';

var zoom = function(media) {
  const img = media.querySelector('img');
  const button = media.querySelector('.media__img');
  const main = document.querySelector('main');

  const klassPageZoom = 'page-zoom';
  const klassZoom = 'media--willzoom';
  const klassZooming = 'media--zooming';
  const klassLoading = 'media--loading';
  const klassZoomed = 'media--zoomed';
  const margin = 80;
  const marginSmall = 20;

  let clone = null;
  let cloneWidth = 0;
  let cloneHeight = 0;
  let cloned = false;
  let isZoomed = false;
  let isZoomedReady = false;
  let imageTop = 0;
  let imageLeft = 0;
  let lastScrollPos = 0;

  function _handleClicks(event) {
    if (!isZoomed) {
      _zoomIn();
    } else {
      _zoomOut();
    }
  }

  function _zoomOutOverlay(event) {
    if (isZoomedReady) {
      _zoomOut();
    }
  }

  function _keyPressed(event) {
    if (event.which === keyCodes.escape || event.keyCode === keyCodes.escape) {
      if (isZoomed) {
        _zoomOut();
      }
    }
  }

  function _getScroll() {
    lastScrollPos = window.pageYOffset;
    const latency = 15;

    if(lastScrollPos < imageTop - latency || lastScrollPos > imageTop + latency ) {
      _zoomOut();
    } else if(isZoomed) {
      window.requestAnimationFrame(_getScroll);
    }
  }

  function _zoomIn(event) {
    if(isZoomed) {
      return false;
    }

    isZoomed = true;

    if (!clone) {
      _clone();
    } else {
      _callbackLoadClone();
    }
  }

  function _callbackLoadClone() {
    imageTop = window.pageYOffset;
    imageLeft = window.pageXOffset;

    media.classList.add(klassZoom);
    media.style.zIndex = '20'; // variables.scss : $z-index-zoom:20;
    media.classList.add(klassZooming);
    media.classList.add(klassZoomed);
    document.documentElement.classList.add(klassPageZoom);

    // initial position
    const { top, left, width, height } = img.getBoundingClientRect();
    clone.style.top = `${top + imageTop}px`;
    clone.style.left = `${left + imageLeft}px`;
    clone.style.width = `${width}px`;
    clone.style.height = `${height}px`;
    clone.style.transform = '';

    _getScroll();
    _animateIn();

    // Addtional ways to close the zoom
    window.addEventListener('resize', _zoomOut, false);
    window.addEventListener('keyup', _keyPressed, false);

    setTimeout(function () {
      media.addEventListener('click', _zoomOutOverlay, false);
      isZoomedReady = true;
    }, 100);
  }

  function _animateIn() {
    const viewportLeft = 0;
    const viewportTop = 0;
    const sideMargin = window.innerWidth < 600 ? marginSmall : margin;
    const naturalWidth = cloneWidth ? cloneWidth : img.naturalWidth;
    const naturalHeight = cloneHeight ? cloneHeight : img.naturalHeight;

    const viewportWidth = window.innerWidth - sideMargin * 2;
    const viewportHeight = window.innerHeight - sideMargin * 2;
    const { top, left, width, height } = img.getBoundingClientRect();

    const scaleX = Math.min(naturalWidth, viewportWidth) / width;
    const scaleY = Math.min(naturalHeight, viewportHeight) / height;
    const scale = Math.min(scaleX, scaleY) || 1;
    const translateX = (-left + (viewportWidth - width) / 2 + sideMargin + viewportLeft) / scale;
    const translateY = (-top + (viewportHeight - height) / 2 + sideMargin + viewportTop) / scale;
    const transform = `scale(${scale}) translate3d(${translateX}px, ${translateY}px, 0)`;

    clone.style.visibility = 'visible';
    clone.style.transform = transform;
  }

  function _clone() {
    cloned = true;

    clone = img.cloneNode();

    clone.removeAttribute('data-srcset');
    clone.removeAttribute('srcset');
    clone.removeAttribute('sizes');
    clone.removeAttribute('id');
    clone.className = 'media__clone';

    media.classList.add(klassLoading);
    main.appendChild(clone);

    const cloneImg = new Image();
    cloneImg.onload = function() {
      cloneWidth  = cloneImg.naturalWidth;
      cloneHeight = cloneImg.naturalHeight;

      clone.addEventListener('click', _zoomOut, false);
      media.classList.remove(klassLoading);

      _callbackLoadClone();
    };
    cloneImg.src = clone.src;
  }

  function _zoomOut(event) {

    isZoomedReady = false;

    if(clone) {
      clone.style.transform = '';
      media.classList.remove(klassZooming);

      setTimeout(function () {
        media.classList.remove(klassZoomed);
        media.classList.remove(klassZoom);
        media.style.zIndex = '';
        clone.style.visibility = '';
        clone.style.top = '';
        clone.style.width = '';
        clone.style.height = '';

        document.documentElement.classList.remove(klassPageZoom);

        isZoomed = false;
      }, 300);
    }

    window.removeEventListener('resize', _zoomOut);
    window.removeEventListener('keyup', _keyPressed);
    media.removeEventListener('click', _zoomOutOverlay);
  }

  function _init() {
    button.addEventListener('click', _handleClicks, false);
  }

  this.destroy = function() {
    button.removeEventListener('click', _handleClicks, false);

    if(clone) {
      clone.removeEventListener('click', _zoomOut, false);
    }

    if(isZoomed) {
      window.removeEventListener('resize', _zoomOut);
      window.removeEventListener('keyup', _keyPressed);
      media.removeEventListener('click', _zoomOutOverlay);
    }

    // remove properties of this behavior
    purgeProperties(this);
  };

  this.init = function() {
    _init();
  };
};

export default zoom;
